import { Component, EventEmitter, Input, Output } from '@angular/core';

import { AppComponent } from '~/core/app.component';

import { ReleaseNotesComponent } from '~/core/components/release-notes/release-notes.component';

@Component({
	selector: 'core-footer',
    standalone: false,
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss']
})

export class FooterComponent {
    @Input() version: any = "0.0.0";
	@Output() setLayoutCssClass = new EventEmitter();

	public date = new Date();

	//*************************************************************************
	//  Component Life-Cycle Methods
	//*************************************************************************
	constructor(
		private app: AppComponent,
	) { }

	public async ngOnInit() {
		// this.isNewRelease();
	}

	//*************************************************************************
	//  Public Methods
	//*************************************************************************
	public showReleaseNotes() {
		this.app.modal = {
			type: <any>ReleaseNotesComponent,
			inputs: {},
			outputs: {
				'closeEvent': () => {
					this.app.modal = undefined;
				}
			}
		}
	}
}
