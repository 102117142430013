import { Component } from '@angular/core';

import { AppSettingsService } from '~/core/services/appsettings.service';
import { AppSettings } from '~/core/models/appsettings';

@Component({
    selector: 'access',
    standalone: false,
    templateUrl: './access.component.html',
    styleUrls: ['./access.component.scss']
})

export class AccessComponent {
    private appSettings: AppSettings = new AppSettings();

    public title: string = "";
    public type: string = "application";
    public supportLink = "";

    //*************************************************************************
    //  Component Life-Cycle Methods
    //*************************************************************************
    constructor(
        private readonly appSettingsService: AppSettingsService
    ) { }

    public async ngOnInit() {
		if (window.history.state.type && window.history.state.type !== "") {
			this.type = window.history.state.type;
		}

        this.appSettings = await this.appSettingsService.getSettings();

        this.title = this.appSettings.title;
        this.supportLink = this.appSettings.supportLink;
    }
}
