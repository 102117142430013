<div class="component" [ngClass]="{ 'not-locked': !appSettings.footer.locked }">
    <div class="contact-info">
        <h3>Contact Us</h3>
        <p>Email: <a [href]="'mailto:' + email">{{ email }}</a></p>
        <p>Phone: <a [href]="'tel:' + phone">{{ phone }}</a></p>
        <p>Address: {{ address }}</p>
    </div>

    <div class="social-media">
        <h3>Follow Us</h3>
        <div class="social-icons">
            <a href="https://facebook.com" target="_blank" aria-label="Facebook">
                <i class="fab fa-facebook"></i>
            </a>
            <a href="https://linkedin.com" target="_blank" aria-label="LinkedIn">
                <i class="fab fa-linkedin"></i>
            </a>
            <a href="https://twitter.com" target="_blank" aria-label="Twitter">
                <i class="fab fa-twitter"></i>
            </a>
            <a href="https://snapchat.com" target="_blank" aria-label="Snapchat">
                <i class="fab fa-snapchat"></i>
            </a>
            <a href="https://tiktok.com" target="_blank" aria-label="TikTok">
                <i class="fab fa-tiktok"></i>
            </a>
        </div>
    </div>
</div>