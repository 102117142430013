import { NgModule } from '@angular/core';

//******************************************************************************
//  Import Core Components
//******************************************************************************
import { CoreImports } from '~/core/classes/imports/core.imports';

//******************************************************************************
//  Import External Components
//******************************************************************************
import { ExternalImports } from '~/classes/imports/external.imports';

//******************************************************************************
//  Services
//******************************************************************************

//******************************************************************************
//  Pages
//******************************************************************************
import { HomeComponent } from '~/pages/home/home.component';
import { DataComponent } from '~/pages/data/data.component';
import { TabsComponent } from '~/pages/tabs/tabs.component';
import { IntroTabComponent } from '~/pages/tabs/intro-tab/intro-tab.component';
import { BasicsTabComponent } from '~/pages/tabs/basics-tab/basics-tab.component';
import { AdvancedTabComponent } from '~/pages/tabs/advanced-tab/advanced-tab.component';
import { AboutComponent } from '~/pages/about/about.component';
import { ContactComponent } from '~/pages/contact/contact.component';
import { SitemapComponent } from '~/pages/sitemap/sitemap.component';

//******************************************************************************
//  App Components
//******************************************************************************
import { HeaderComponent } from '~/components/layout/header/header.component';
import { FooterComponent } from '~/components/layout/footer/footer.component';

const components: any[] = [
    HomeComponent,    
    DataComponent,
    TabsComponent,
    IntroTabComponent,
    BasicsTabComponent,
    AdvancedTabComponent,
    AboutComponent,
    ContactComponent,
    SitemapComponent,

    HeaderComponent,
    FooterComponent,
];

const services: any[] = [
];

@NgModule({
    imports: [
        CoreImports,
        ExternalImports,
    ],
    declarations: [
        components,
    ],
    exports: [
        components,
    ],
    providers: [
        services,
    ],
})

export class AppImports { }
