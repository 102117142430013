<footer class="content">
    <div class="copyright">Copyright &copy; {{ date | date:'yyyy' }} soo-doh-kohd</div>
    <div class="logo">
        <img title="soo-doh-kohd" alt="soo-doh-kohd" src="assets/images/sdk.png" height="40">
    </div>
    <div class="copyright-mobile">
        <div>Copyright &copy; {{ date | date:'yyyy' }}</div>
        <div>soo-doh-kohd</div>
    </div>
    <div class="access" (click)="showReleaseNotes()">Version: {{ version }}</div>
</footer>

