import { Component, EventEmitter, Input, Output } from '@angular/core';

import { BaseComponent } from '~/pages/base.component';

@Component({
    selector: 'tab-nav',
    standalone: false,
    templateUrl: './tab-nav.component.html',
    styleUrls: ['./tab-nav.component.scss']
})

export class TabNavComponent extends BaseComponent {
    @Input() data: any;
    @Output() tabChangedEvent: EventEmitter<any> = new EventEmitter();

    //*************************************************************************
    //  Component Life-Cycle Methods
    //*************************************************************************
    public async ngOnInit() {
        await super.ngOnInit();
    }

    //*************************************************************************
    //  Public Methods
    //*************************************************************************
	public setData() {
        this.tabChangedEvent.emit(this.data);
	}

    public setPosition(event: any) {
        this.data.nav.position = event[0];

        this.setData();
    }

    public setItems(event: any) {
        this.data.nav.items = event[0];

        this.setData();
    }
}
