<div *ngIf="data" class="content">
    <div class="data">
        <div>
            <span class="label">Height</span>
            <span class="icon" [title]="'The height of the header section.'">info</span>
            <sdk-textbox [value]="data.header.height" style="color: var(--gray)" (changeCallBackEvent)="data.header.height = $event; setData()"></sdk-textbox>
        </div>

        <div>
            <span class="label">Environment *</span>
            <span class="icon" [title]="'Show the current environment after the title.\nNOTE: Used ONLY if \'Custom\' is disabled.'">info</span>
            <br />
            <span class="icon checkbox" (click)="data.header.environment = !data.header.environment; setData()">{{ data.header.environment ? 'check_box' : 'check_box_outline_blank' }}</span>
        </div>

        <div>
            <span class="label">Custom</span>
            <span class="icon" [title]="'Use a custom header component.'">info</span>
            <br />
            <span class="icon checkbox" (click)="data.header.custom = !data.header.custom; setData()">{{ data.header.custom ? 'check_box' : 'check_box_outline_blank' }}</span>
        </div>
</div>
