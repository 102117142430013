import { Component } from '@angular/core';

import { BaseComponent } from '~/pages/base.component';

@Component({
    selector: 'sitemap',
    standalone: false,
    templateUrl: './sitemap.component.html',
    styleUrls: ['./sitemap.component.scss']
})

export class SitemapComponent extends BaseComponent {
    public routes: any = [];
    public subRoutes: any = [];

    //*************************************************************************
    //  Component Life-Cycle Methods
    //*************************************************************************
    constructor() {
        super();
    }

    public async ngOnInit() {
        await super.ngOnInit();

        this.getRoutes();
    }

    public navigateTo(route: any) {
        window.location.href = `${window.location.origin}/${route.path}`;
    }


    //*************************************************************************
    //  Private Methods
    //*************************************************************************
    private async getRoutes() {
        this.routes = [];
        this.subRoutes = [];

        this.appSettings.seo.forEach((route: any) => {
            if (route.isNav && route.isActive) {
                let addItem: boolean = false;

                if (route.roles.length > 0 && this.appSettings.user) {
                    if (route.roles.some((r: any) => this.appSettings.user.roles.includes(r))) {
                        addItem = true;
                    }
                } else {
                    addItem = true;
                }

                if (addItem) {
                    this.routes.push(route);
                }
            }
        });
    }
}
