<div *ngIf="data" class="content">
    <div class="data">
        <div>
            <span class="label">Position</span>
            <span class="icon" [title]="'Set the position of the navigation menu.'">info</span>
            <sdk-select [options]="['top', 'left']" [selectedOptions]="[data.nav.position]" [optionValuesStyle]="'max-height: 250px'" (selectChangeEvent)="setPosition($event)"></sdk-select>
        </div>

        <div>
            <span class="label">Items *</span>
            <span class="icon" [title]="'Set the location of the navigation items.\nNOTE: Used ONLY if \'Position\' is \'top\'.'">info</span>
            <sdk-select [options]="['left', 'center', 'right']" [selectedOptions]="[data.nav.items]" [optionValuesStyle]="'max-height: 250px'" (selectChangeEvent)="setItems($event)"></sdk-select>
        </div>

        <div>
            <span class="label">Height</span>
            <span class="icon" [title]="'The height of the header section.'">info</span>
            <sdk-textbox [value]="data.nav.height" style="color: var(--gray)" (changeCallBackEvent)="data.nav.height = $event; setData()"></sdk-textbox>
        </div>

        <div>
            <span class="label">Visible</span>
            <span class="icon" [title]="'Show the navigation.'">info</span>
            <br />
            <span class="icon checkbox" (click)="data.nav.visible = !data.nav.visible; setData()">{{ data.nav.visible ? 'check_box' : 'check_box_outline_blank' }}</span>
        </div>

        <div>
            <span class="label">Sizable</span>
            <span class="icon" [title]="'Show Sizable option.'">info</span>
            <br />
            <span class="icon checkbox" (click)="data.nav.sizable = !data.nav.sizable; setData()">{{ data.nav.sizable ? 'check_box' : 'check_box_outline_blank' }}</span>
        </div>

        <div>
            <span class="label">AppMode</span>
            <span class="icon" [title]="'Show AppMode option.'">info</span>
            <br />
            <span class="icon checkbox" (click)="data.nav.appMode = !data.nav.appMode; setData()">{{ data.nav.appMode ? 'check_box' : 'check_box_outline_blank' }}</span>
        </div>

        <div>
            <span class="label">Icons</span>
            <span class="icon" [title]="'Show icons.'">info</span>
            <br />
            <span class="icon checkbox" (click)="data.nav.icons = !data.nav.icons; setData()">{{ data.nav.icons ? 'check_box' : 'check_box_outline_blank' }}</span>
        </div>
</div>
