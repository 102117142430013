<main class="content">
    <div class="bg"></div>
    <div class="data">
        <div class="frame">
            <div class="message">
                <h2 class="blue-dark">Build Your Next Website or Enterprise Application with Ease</h2>
                <p>Our Angular Starter Kit is designed to be the perfect foundation for any website or enterprise application. Whether you're building a small business website or a large-scale enterprise solution, this kit comes pre-loaded with all the essential components you'll need to get started. Best of all, it's ready to run out of the box!</p>
                <p >In addition to the starter kit, the SDK Angular Components serve as powerful tools that offer easy-to-use, plug-and-play functionality, enabling you to seamlessly enhance your project with commonly used elements in professional web and enterprise applications. Whether it's data grids, tabs, custom textboxes and select boxes, Google Maps integration, and more, each SDK Angular Component is designed to be reusable, efficient, and adaptable, giving you the flexibility to customize and scale your application with ease.</p>
                <p>Start your next project faster, with fewer headaches, and focus on what truly matters — building great user experiences.</p>
            </div>
        </div>

        <footer *ngIf="appSettings.footer.visible && appSettings.footer.custom && !appSettings.footer.locked"></footer>
    </div>
</main>
