<div class="content">
    <sdk-message [(message)]="message"></sdk-message>

    <div class="header">
        <span class="title">Configure App Settings</span>
        <span title="Copy AppSettings" class="icon copy" [cdkCopyToClipboard]="json" [cdkCopyToClipboardAttempts]="4" (click)="copy()">content_paste</span>
        <span title="Close" class="icon close" (click)="close()">close</span>
    </div>

    <div class="tabs">
        <sdk-tabs [tabs]="tabs" activeFontColor="var(--blue)"></sdk-tabs>
    </div>

    <div class="footer">
        <div class="note">* Indicates special note inside <span class="icon" [title]="'Info icon'">info</span></div>
        <div class="actions">
            <button (click)="apply()">Apply Settings</button>
            <button class="green" (click)="website()">Website Demo</button>
            <button class="green" (click)="webapp()">Webapp Demo</button>
        </div>
    </div>
</div>
