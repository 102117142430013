<div class="content">
    <div class="header">
        <span class="title">Release Notes</span>
        <span title="Close" class="icon close" (click)="close()">close</span>
    </div>

    <div #data class="data">
        <div class="text" [innerHTML]="readmeFile | safe: 'html'"></div>
    </div>

    <sdk-loading [isLoading]="isLoading"></sdk-loading>
</div>
