<div *ngIf="data" class="content">
    <div class="data">
        <div>
            <span class="label">Height</span>
            <span class="icon" [title]="'The height of the header section.'">info</span>
            <sdk-textbox [value]="data.footer.height" style="color: var(--gray)" (changeCallBackEvent)="data.footer.height = $event; setData()"></sdk-textbox>
        </div>

        <div>
            <span class="label">Visible</span>
            <span class="icon" [title]="'Show the footer section'">info</span>
            <br />
            <span class="icon checkbox" (click)="data.footer.visible = !data.footer.visible; setData()">{{ data.footer.visible ? 'check_box' : 'check_box_outline_blank' }}</span>
        </div>

        <div>
            <span class="label">Custom</span>
            <span class="icon" [title]="'Use a custom footer component.'">info</span>
            <br />
            <span class="icon checkbox" (click)="data.footer.custom = !data.footer.custom; setData()">{{ data.footer.custom ? 'check_box' : 'check_box_outline_blank' }}</span>
        </div>

        <div>
            <span class="label">Locked *</span>
            <span class="icon" [title]="'Is the footer locked on the page.\nNOTE: Used ONLY if \'Custom\' is enabled.'">info</span>
            <br />
            <span class="icon checkbox" (click)="data.footer.locked = !data.footer.locked; setData()">{{ data.footer.locked ? 'check_box' : 'check_box_outline_blank' }}</span>
        </div>
    </div>
</div>
