<main class="content">
    <div class="bg"></div>
    <div class="data">
        <div class="frame">
            <div class="location">
                <div style="position: relative; height: 100%; width: 100%;">
                    <sdk-google-map location="Murrieta, CA, USA" height="100%" width="100%" isLoading="true" zoom="10"></sdk-google-map>
                </div>
            </div>
            <div class="contact text">
                <div class="title">Email us</div>
                <div><img src="assets/images/email.jpg" alt="Contact Us" title="Email Us" /></div>
            </div>
            <div class="share text">
                <div class="title">Share with us</div>
                <div style="padding: 10px 5px;">Have an idea or want to contribute? Join the SDK Community today.</div>
            </div>
        </div>

        <footer *ngIf="appSettings.footer.visible && appSettings.footer.custom && !appSettings.footer.locked"></footer>
    </div>
</main>
