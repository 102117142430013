import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, DatePipe, DecimalPipe } from '@angular/common';
import { ClipboardModule } from '@angular/cdk/clipboard';

//******************************************************************************
//  NPM (Packages) External
//******************************************************************************
import { SDKLoadingModule } from 'sdk-loading';
import { SDKMessageModule } from 'sdk-message';
import { SDKSelectModule } from 'sdk-select';
import { SDKTabsModule } from 'sdk-tabs';
import { SDKTextboxModule } from 'sdk-textbox';

//******************************************************************************
//  Services
//******************************************************************************
import { AppSettingsService } from '~/core/services/appsettings.service';
import { GraphService } from '~/core/services/graph.service';
import { FormatterService } from '~/core/services/formatter.service';

//******************************************************************************
//  Pages
//******************************************************************************
import { AccessComponent } from '~/core/pages/access/access.component';
import { AuthComponent } from '~/core/pages/auth/auth.component';
import { ErrorComponent } from '~/core/pages/error/error.component';
import { NotFoundComponent } from '~/core/pages/notfound/notfound.component';

//******************************************************************************
//  Core Components
//******************************************************************************
import { HeaderComponent } from '~/core/components/layout/header/header.component';
import { NavComponent } from '~/core/components/layout/nav/nav.component';
import { FooterComponent } from '~/core/components/layout/footer/footer.component';
import { AlertComponent } from '~/core/components/alert/alert.component';
import { ReleaseNotesComponent } from '~/core/components/release-notes/release-notes.component';

import { ConfigureComponent } from '~/core/components/configure/configure.component';
import { TabCoreComponent } from '~/core/components/configure/tab-core/tab-core.component';
import { TabHeaderComponent } from '~/core/components/configure/tab-header/tab-header.component';
import { TabNavComponent } from '~/core/components/configure/tab-nav/tab-nav.component';
import { TabFooterComponent } from '~/core/components/configure/tab-footer/tab-footer.component';
import { TabSeoComponent } from '~/core/components/configure/tab-seo/tab-seo.component';

//******************************************************************************
//  Pipes
//******************************************************************************
import { SafePipe } from '~/core/pipes/safe.pipe';

const components: any[] = [
    HeaderComponent,
    NavComponent,
    FooterComponent,
    AccessComponent,
    AlertComponent,
    ErrorComponent,
    ReleaseNotesComponent,

    ConfigureComponent,
    TabCoreComponent,
    TabHeaderComponent,
    TabNavComponent,
    TabFooterComponent,
    TabSeoComponent,

    AuthComponent,
    NotFoundComponent,
];

const services: any[] = [
    AppSettingsService,
    GraphService,
    FormatterService,
];

@NgModule({
    imports: [
        CommonModule,
        CurrencyPipe,
        DatePipe,
        DecimalPipe,
        ClipboardModule,

        SDKLoadingModule,
        SDKMessageModule,
        SDKSelectModule,
        SDKTabsModule,
        SDKTextboxModule,
    ],
    declarations: [
        components,
        SafePipe,
    ],
    exports: [
        components,
    ],
    providers: [
        services,
    ],
})

export class CoreImports { }
