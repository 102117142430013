import { Component } from '@angular/core';

import { BaseComponent } from '~/pages/base.component';

@Component({
    selector: 'contact',
    standalone: false,
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss']
})

export class ContactComponent extends BaseComponent {
    //*************************************************************************
    //  Component Life-Cycle Methods
    //*************************************************************************
    constructor() {
        super();
    }

    public async ngOnInit() {
        await super.ngOnInit();
    }
}
