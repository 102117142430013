<main class="content">
    <div class="data">
        <div class="message">
            <h2 class="blue-dark">Helping developers succeed through the power of SDK.</h2>
            <p>
                At SDK, our mission is simple: to empower developers with the tools and standards they need to achieve
                success in today's fast-paced, ever-evolving tech landscape. We understand that the demands on
                developers are greater than ever, with tight deadlines, complex projects, and the constant need to
                innovate. That's why we're dedicated to providing a comprehensive suite of Software Development Kits
                (SDKs) that streamline the development process, reduce time-to-market, and ensure that the products you
                create are of the highest quality.
                <br /><br />
                Our SDKs are designed with developers in mind, offering robust, easy-to-integrate solutions that help
                you focus on what you do best—coding and creating. Whether you're working on mobile apps, web
                development, or emerging technologies like AI and IoT, our tools are built to be flexible, reliable, and
                up-to-date with the latest industry standards. We take the complexity out of development, so you can
                spend more time innovating and less time troubleshooting.
                <br /><br />
                But we don't just stop at providing tools. SDK is committed to fostering a community of developers who
                can learn, grow, and succeed together. Through our extensive documentation, tutorials, and support
                channels, we ensure that you have everything you need to overcome challenges and push the boundaries of
                what's possible. Our goal is to be your trusted partner in every step of your development journey,
                helping you deliver top-quality products that stand out in the market.
                <br /><br />
                With SDK by your side, you can save time, reduce costs, and accelerate your development cycle—all while
                delivering exceptional products that meet the highest standards of excellence.
            </p>
        </div>
        <div class="sdk">
            <img title="SDK" alt="sdk" src="assets/images/sdk.png" height="65">
            <h3 class="blue-dark">The final piece to your technology puzzle.</h3>
        </div>

        <footer *ngIf="appSettings.footer.visible && appSettings.footer.custom && !appSettings.footer.locked"></footer>
    </div>
</main>