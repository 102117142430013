import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';

import { BaseComponent } from '~/pages/base.component';

@Component({
    selector: 'tab-seo',
    standalone: false,
    templateUrl: './tab-seo.component.html',
    styleUrls: ['./tab-seo.component.scss']
})

export class TabSeoComponent extends BaseComponent {
    @Input() data: any;
    @Output() tabChangedEvent: EventEmitter<any> = new EventEmitter();

    @ViewChild('sdktextbox') sdktextbox!: any;

    public page: any;
    public pages: any;

    public ord: number = 0;
    public role: string = "";
    public adjustRole: string = "";
    public orderPattern: string = "[^1-9]";

    //*************************************************************************
    //  Component Life-Cycle Methods
    //*************************************************************************
    public async ngOnInit() {
        await super.ngOnInit();

        this.getPages();
    }

    //*************************************************************************
    //  Public Methods
    //*************************************************************************
    public setData() {
        let ndx = this.data.seo.findIndex((s: any) => s.path === this.page.path);

        if (ndx > -1) {
            this.data.seo[ndx] = this.page;
        }

        this.getPages();

        this.tabChangedEvent.emit(this.data);
    }

    public setPage(event: any) {
        let page = event[0] === "[home]" ? "" : event[0];

        let ndx = this.data.seo.findIndex((s: any) => s.path === page);

        this.ord = 1;

        if (ndx > -1) {
            this.page = this.data.seo[ndx];
            this.ord = ndx + 1;
        } else {
            this.page = {
                path: "sdk_page",
                title: "New Page",
                icon: "sdk",
                isNav: true,
                roles: [],
                metaTitle: "",
                metaDescription: "",
                metaKeywords: ""
            };

            this.data.seo.push(this.page);

            this.setData();
            this.orderData(1);
        }
    }

    public orderData(event: any) {
        if (event) {
            this.moveItemInArray(this.data.seo, this.ord - 1, event - 1);

            this.getPages();
        }

        this.setPage([this.page.path]);
    }

    public setValue(event: any) {
        this.role = event;
    }

    public setAdjustValue(event: any) {
        this.role = event;
        this.adjustRole = event;
        this.sdktextbox.text.nativeElement.focus();
    }

    public addValue() {
        let ndx = this.page.roles.findIndex((value: any) => value === this.role.trim());

        if (ndx === -1 && this.role.trim() !== "") {
            this.page.roles.push(this.role.trim());
        }

        this.deleteValue(this.adjustRole);

        this.sortRoles();
        this.setData();

        this.role = "";
        this.adjustRole = "";
        this.sdktextbox.text.nativeElement.focus();
    }

    public deleteValue(event: any) {
        let ndx = this.page.roles.findIndex((value: any) => value === event);

        if (ndx > -1) {
            this.page.roles.splice(ndx, 1);
        }

        this.setData();
    }

    //*************************************************************************
    //  Private Methods
    //*************************************************************************
    private getPages() {
        this.pages = this.data.seo.map((page: any) => page.path === "" ? "[home]" : page.path);

        this.orderPattern = `[1-${this.pages.length}]`;

        this.pages.unshift("[Add new page...]");
    }

    private sortRoles() {
        this.page.roles.sort((a: any, b: any) => a.localeCompare(b));
    }

    private moveItemInArray<T>(array: T[], fromIndex: number, toIndex: number): void {
        if (fromIndex < 0 || fromIndex > array.length || toIndex < 0 || toIndex > array.length) {
            return;
        }

        let [item] = array.splice(fromIndex, 1); // Remove the item

        array.splice(toIndex, 0, item); // Insert the item at the new index
    }
}
