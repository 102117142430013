import { Component } from '@angular/core';

import { Subscription } from 'rxjs';

import { SDKDataGridColumn, SDKDataGridOptions, Filters, Sorts, FilterType } from 'sdk-datagrid';

import { HttpService } from '~/core/services/http.service';
import { FormatterService } from '~/core/services/formatter.service';

import { BaseComponent } from '~/pages/base.component';
import { Clone } from '~/core/utils/clone';

@Component({
    selector: 'data',
    standalone: false,
    templateUrl: './data.component.html',
    styleUrls: ['./data.component.scss']
})

export class DataComponent extends BaseComponent {
    public activeDataset: string = "Earthquakes";
    public datasets: any[] = [
        {
            Title: "Earthquakes",
            Source: `https://earthquake.usgs.gov/fdsnws/event/1/query?format=geojson&starttime=${this.formatterService.formatDate(new Date(), "yyyy-MM-dd")}`
        }
    ];
    public columns: SDKDataGridColumn[] = [
        { Name: "title", DisplayName: "Earthquake", FilterTypes: [Filters.Contains] },
        { Name: "mag", DisplayName: "Magnitude", FilterType: FilterType.Dropdown, FilterTypes: [Filters.Equals, Filters.GreaterThanOrEqual, Filters.LessThanOrEqual], FilterValues: [1, 2, 3, 4, 5] },
        { Name: "place", DisplayName: "Location", FilterTypes: [Filters.Contains] },
        { Name: "updated", DisplayName: "Time", showFilter: false, formatData: (value: any) => this.formatterService.formatDate(value, "yyyy-MM-dd hh:mm:ss") },
    ];
    public options: SDKDataGridOptions = {};

    public data: any;
    public dataExport: any;
    public rows: number | undefined;
    public page: number | undefined;
    public total: number | undefined;
    public error: string = "";
    public updateGrid: boolean = true;

    private dataSubscription!: Subscription
    private rawData: any;

    //*************************************************************************
    //  Component Life-Cycle Methods
    //*************************************************************************
    constructor(
        private readonly httpService: HttpService,
        private readonly formatterService: FormatterService,
    ) {
        super();
    }

    public async ngOnInit() {
        await super.ngOnInit();

        this.isLoading = true;
    }

    public async ngOnDestroy() {
        if (this.dataSubscription) {
            this.dataSubscription.unsubscribe();
        }
    }

    //*************************************************************************
    //  Public Methods
    //*************************************************************************
    public async loadData(event: any = null) {
        try {
            this.isLoading = true;

            let endpoint: string = this.datasets.filter((ds: any) => ds.Title === this.activeDataset)[0].Source;

            this.dataSubscription = this.httpService.GetExternal(endpoint).subscribe({
                next: (response: any) => {
                    this.rawData = response;
                    this.error = "";

                    this.processData(event);
                },
                error: (error: any) => {
                    this.rawData = null;
                    this.error = error.message;
                    this.isLoading = false;
                }
            });

        } catch (error: any) {
            this.error = error.message;

            this.isLoading = false;
        }
    }

    public showDetails(event: any) {
        if (event?.record) {
            window.open(event.record.url, "_blank");
        }
    }

    //*************************************************************************
    //  Private Methods
    //*************************************************************************
    private processData(event: any = null) {
        let page = 1;
        let rows = 100;
        let start = (page - 1) * rows;
        let end = page * rows;

        if (event) {
            page = parseInt(event.page);
            rows = parseInt(event.rows);
            start = (page - 1) * rows;
            end = page * rows;
        }

        let _tmp: any = Clone.deepCopy(this.rawData);
        let _data: any = [];

        _tmp.features.forEach((feature: any) => {
            _data.push(feature.properties);
        });

        if (event && event.action === "Export Data") {
            this.dataExport = _data;

            this.isLoading = false;
        } else {
            _data = this.filterData(_data);
            _data = this.sortData(_data);
    
            this.total = _data.length;

            this.data = _data.slice(start, end);

            this.rows = rows;
            this.page = page;

            setTimeout(() => {
                this.updateGrid = true; // Tell the grid to update.

                this.isLoading = false;
            }, 100);
        }
    }

    private filterData(data: any) {
        this.columns.filter((column: SDKDataGridColumn) => column.Filter).forEach((column: SDKDataGridColumn) => {
            let operation: any = column.Filter?.operation![0].toString();
            let filterKey = Object.values(Filters).find(value => value === operation);

            switch (filterKey) {
                case Filters.Contains:
                    data = data.filter((d: any) => d[column.Name].toString().toLocaleLowerCase().includes(column.Filter?.value.toString().toLocaleLowerCase())).slice(0);
                    break;
                case Filters.GreaterThanOrEqual:
                    data = data.filter((d: any) => d[column.Name] >= column.Filter?.value).slice(0);
                    break;
                case Filters.LessThanOrEqual:
                    data = data.filter((d: any) => d[column.Name] <= column.Filter?.value).slice(0);
                    break;
                default:
                    break;
            }
        });

        return data;
    }

    private sortData(data: any) {
        this.columns
            .filter((column: SDKDataGridColumn) => column.Sort)
            .forEach((column: SDKDataGridColumn) => {
                if (column.Sort?.direction === Sorts.ASC) {
                    data = data.sort((a: any, b: any) => (a[column.Name] > b[column.Name]) ? 1 : -1).slice(0);
                } else {
                    data = data.sort((a: any, b: any) => (a[column.Name] < b[column.Name]) ? 1 : -1).slice(0);
                }
            });

        return data;
    }
}
