import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NavigationEnd, Router, Event } from '@angular/router';

import { Subscription } from 'rxjs';

import { AppSettingsService } from '~/core/services/appsettings.service';
import { AppSettings } from '~/core/models/appsettings';

export class NavItem {
    public title: string = "";
    public icon: string = "";
    public path: string = "";
}

@Component({
    selector: 'nav',
    standalone: false,
    templateUrl: './nav.component.html',
    styleUrls: ['./nav.component.scss']
})

export class NavComponent {
    @Input() expand: boolean = false;
    @Output() setLayoutClass = new EventEmitter();
    @Output() reloadPage = new EventEmitter();
    @Output() showSearch: EventEmitter<boolean> = new EventEmitter();

    @ViewChild("nav") nav!: ElementRef | undefined;

    public appSettings: AppSettings = new AppSettings();
    public appIcon: string = "web_asset";
    public appTitle: string = "Application Mode";

    public menuIcon: string = "keyboard_double_arrow_right";
    public activeItem: NavItem = new NavItem();

    public navItems: any = [];
    public navSubItems: any = [];

    private collapse: boolean = false;
    private routerSubscription: Subscription | undefined;

    //*************************************************************************
    //  Component Life-Cycle Methods
    //*************************************************************************
    constructor(
        private readonly router: Router,
        private readonly appSettingsService: AppSettingsService,
        private readonly changeDetectorRef: ChangeDetectorRef,
    ) { }

    public async ngOnInit() {
        this.routerSubscription = this.router.events.subscribe((event: Event) => {
            if (event instanceof NavigationEnd) {
                this.handleNavigationChange();
            }
        });

        await this.initializeSettings();
    }

    public ngOnDestroy() {
        if (this.routerSubscription) {
            this.routerSubscription.unsubscribe();
        }
    }

    //*************************************************************************
    //  Public Methods
    //*************************************************************************
    public toggleMenu(collapse: boolean = false) {
        this.collapse = collapse;

        this.setLayoutClass.emit();
    }

    public navigateTo(item: NavItem) {
        let allowNavigate: boolean = true;
        let storage = sessionStorage.getItem("__allowNavigate__");

        if (storage && storage === "false") {
            allowNavigate = confirm('WARNING: Changes have been made. Are you sure you want to leave without saving these changes?');
        }

        if (this.collapse) {
            this.collapse = false;
            this.setLayoutClass.emit();
        }

        if (allowNavigate) {
            this.router.navigateByUrl(item.path);
            this.activeItem = item;
        }
    }

    public toggleFullscreen() {
        if (!document.fullscreenElement) {
            document.documentElement.requestFullscreen();
            this.appIcon = "iframe";
            this.appTitle = "Web Mode";
        } else {
            document.exitFullscreen();
            this.appIcon = "web_asset";
            this.appTitle = "Application Mode";
        }
    }

    //*************************************************************************
    //  Private Methods
    //*************************************************************************
    private async initializeSettings() {
        this.appSettings = await this.appSettingsService.getSettings();

        await this.setUI();
        await this.getRoutes();

        this.setActiveRoute();
    }

    private async handleNavigationChange() {
        await this.initializeSettings();
    }

    private setActiveRoute() {
        if (this.router?.url) {
            let routeUrl = this.router.url.split("?")[0];

            this.setActiveItem(this.navItems, routeUrl);
            this.setActiveItem(this.navSubItems, routeUrl);
        }
    }

    private async getRoutes() {
        this.navItems = [];
        this.navSubItems = [];

        await this.appSettingsService.getSettings().then((appSettings: AppSettings) => {
            this.appSettings.seo.forEach((route: any) => {
                if (route.isNav && route.isActive) {
                    let addItem: boolean = false;

                    if (route.roles.length > 0 && appSettings.user) {
                        if (route.roles.some((r: any) => appSettings.user.roles.includes(r))) {
                            addItem = true;
                        }
                    } else {
                        addItem = true;
                    }

                    if (addItem) {
                        this.navItems.push(
                            {
                                "title": route.title,
                                "icon": route.icon,
                                "path": route.path
                            }
                        )

                        this.getSubRoutes(route, appSettings);
                    }
                }
            })
        });
    }

    private getSubRoutes(parent: any, appSettings: AppSettings) {
        this.appSettings.seo.forEach((route: any) => {
            if (route.isSubNav && route.isActive && route.path.startsWith(`${parent.path}/`)) {
                let addItem: boolean = false;

                if (route.roles.length > 0) {
                    if (route.roles.some((r: any) => appSettings.roles.includes(r))) {
                        addItem = true;
                    }
                } else {
                    addItem = true;
                }

                if (addItem) {
                    this.navSubItems.push(
                        {
                            "parent": parent.title,
                            "title": route.title,
                            "icon": route.icon,
                            "path": route.path
                        }
                    )
                }
            }
        });
    }

    private setActiveItem(items: any, routeUrl: any) {
        for (let item of items) {
            if (`/${item.path}` === routeUrl) {
                this.activeItem = item;
                break;
            }
        }
    }

    private async setUI() {
        this.changeDetectorRef.detectChanges();

        let element = this.nav?.nativeElement;

        if (element) {
            element.style.setProperty("--height", this.appSettings.nav.height);
            element.style.setProperty("--items", this.appSettings.nav.items);
        }
    }
}
