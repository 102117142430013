<main class="content">
    <div class="data">
        <sdk-datagrid
            title="SDK Datagrid Example"
            titleStyle="display: flex; align-items: center; justify-content: center; font-family: var(--font-header), sans-serif; color: var(--blue-dark); background-color: var(--background); border-bottom: 1px solid var(--border-blue); font-size: 1.5em; font-weight: unset; text-align: center;"
            fontFamily="'GothamNarrowBook', sans-serif, 'Helvetica Neue', Arial"
            columnHeaderStyle="font-family: var(--font-text), sans-serif; color: var(--blue-dark); font-size: 0.9em;"
            [(updateGrid)]="updateGrid"
            [(columns)]="columns"
            [(dataExport)]="dataExport"
            [options]="options"
            [data]="data"
            [rows]="rows"
            [page]="page"
            [total]="total"
            [error]="error"
            [isDebug]="false"
            (selectedRowActionEvent)="showDetails($event)"
            (loadDataEvent)="loadData($event)">
        </sdk-datagrid>
    </div>

    <sdk-loading [isLoading]="isLoading"></sdk-loading>
</main>
