import { Component, EventEmitter, Input, Output } from '@angular/core';

import { AppSettingsService } from '~/core/services/appsettings.service';
import { AppSettings } from '~/core/models/appsettings';

@Component({
    selector: 'footer',
    standalone: false,
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})

export class FooterComponent {
    @Input() version: any = "0.0.0";
    @Output() setLayoutCssClass = new EventEmitter();

    public date = new Date();

    public email: string = "info@soodohkohd.com";
    public phone: string = "+1 (800) 123-4567";
    public address: string = "123 Main St., Anywhere, US 92562";

    public appSettings: AppSettings = new AppSettings();

    //*************************************************************************
    //  Component Life-Cycle Methods
    //*************************************************************************
    constructor(
        private readonly appSettingsService: AppSettingsService,
    ) { }

    public async ngOnInit() {
        await this.initializeSettings();
    }

    //*************************************************************************
    //  Public Methods
    //*************************************************************************
    private async initializeSettings() {
        this.appSettings = await this.appSettingsService.getSettings();
    }

    //*************************************************************************
    //  Private Methods
    //*************************************************************************
}
