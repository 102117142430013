<main class="content">
    <div class="header">
        <div class="title">{{ pageTitle }}</div>
    </div>
    <div class="data">
        <sdk-tabs [share]="true" [tabs]="tabs" tabColor="var(--green-dark)" fontColor="var(--green-light)" activeFontColor="var(--green)"></sdk-tabs>

        <sdk-loading [isLoading]="isLoading"></sdk-loading>
    </div>
</main>
