import { Component } from '@angular/core';

import { BaseComponent } from '~/pages/base.component';

@Component({
    selector: 'home',
    standalone: false,
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})

export class HomeComponent extends BaseComponent {
    //*************************************************************************
    //  Component Life-Cycle Methods
    //*************************************************************************
    constructor() {
        super();
    }

    public async ngOnInit() {
        await super.ngOnInit();
    }
}
