import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';

import { lastValueFrom } from 'rxjs';

import MarkdownIt from 'markdown-it';
import markdownItAnchor from 'markdown-it-anchor';

// Define slugify as a standalone variable for reuse and testability
export const slugifyFn = (s: string): string => s.toLowerCase().replace(/\s+/g, '-');

// Define callback as a standalone variable for reuse and testability
export const callbackFn = (token: any, info: any): void => {
    if (token.tag === 'a') {
        token.attrSet('href', `#${info.slug}`);
    }
};

@Component({
    selector: 'release-notes',
    standalone: false,
    templateUrl: './release-notes.component.html',
    styleUrls: ['./release-notes.component.scss']
})

export class ReleaseNotesComponent {
    @ViewChild("data") data!: ElementRef;
    @Output() closeEvent: EventEmitter<any> = new EventEmitter();

    public isLoading: boolean = true;
    public readmeFile: string = "";

    private readonly http: HttpClient;
    private readonly markdown: any;

    //*************************************************************************
    //  Component Life-Cycle Methods
    //*************************************************************************
    constructor(
        private readonly httpHandler: HttpBackend,
        private readonly cdr: ChangeDetectorRef,
    ) { 
        this.http = new HttpClient(this.httpHandler);

        this.markdown = new MarkdownIt()
            .use(markdownItAnchor, {
                slugify: slugifyFn,
                callback: callbackFn,
            });
    }

    public async ngOnInit() {
        await this.loadReleaseNotesFile();

        this.isLoading = false;
    }

    public ngAfterViewInit() {
        this.setupAnchorLinkHandlers();
    }

    //*************************************************************************
    //  Public Methods
    //*************************************************************************
    public close() {
        this.closeEvent.emit();
    }

    //*************************************************************************
    //  Private Methods
    //*************************************************************************
    private async loadReleaseNotesFile() {
        const stream$ = this.http.get("assets/release-notes.md", {
            headers: new HttpHeaders({
                "Accept": "text/plain, text/markdown, */*"
            }),
            responseType: 'text'
        });
    
        await lastValueFrom(stream$).then((data: string) => {
            this.readmeFile = this.markdown.render(data).replaceAll("<hr", "<br /><hr");
            this.cdr.detectChanges(); // Trigger change detection after setting readmeFile
    
            let headers = document.querySelectorAll('h1, h2');
    
            headers.forEach(header => {
                header.setAttribute("id", header.innerHTML.toLowerCase().replace(/\s+/g, '-'));
            });
        }).catch(error => {
            console.error("Failed to load Release Notes:", error);
        });
    }
        
    private setupAnchorLinkHandlers() {
        setTimeout(() => {
            let anchors = document.querySelectorAll('a[href^="#"]');

            anchors.forEach(anchor => {
                let targetId = anchor.getAttribute('href')?.substring(1);

                anchor.addEventListener('click', (event) => {
                    event.preventDefault();

                    if (targetId) {
                        let targetElement = this.data.nativeElement.querySelector(`#${targetId}`);

                        if (targetElement) {
                            targetElement.scrollIntoView({ behavior: 'smooth' });
                        }
                    }
                });
            });
        }, 100);
    }
}
