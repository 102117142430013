import { Component } from '@angular/core';

import { BaseComponent } from '~/pages/base.component';

import { SDKTab } from 'sdk-tabs';
import { IntroTabComponent } from '~/pages/tabs/intro-tab/intro-tab.component';
import { BasicsTabComponent } from '~/pages/tabs/basics-tab/basics-tab.component';
import { AdvancedTabComponent } from '~/pages/tabs/advanced-tab/advanced-tab.component';

@Component({
    selector: 'tabs',
    standalone: false,
    templateUrl: './tabs.component.html',
    styleUrls: ['./tabs.component.scss']
})

export class TabsComponent extends BaseComponent {
    public tabs: SDKTab[] = [
        {
            title: "TABS",
            type: <any>IntroTabComponent,
            inputs: {
                "title": "Introducing TABS! The easiest way to organize your layout."
            },
            outputs: {
                'tabChangedEvent': (event: any) => {
                    this.tabChanged(event);
                }
            }
        },
        {
            title: "The Basics",
            type: <any>BasicsTabComponent,
            inputs: {
                "title": "The Basics - A simple out-of-the-box design."
            },
            outputs: {
                'tabChangedEvent': (event: any) => {
                    this.tabChanged(event);
                }
            }
        },
        {
            title: "Advanced",
            type: <any>AdvancedTabComponent,
            inputs: {
                "title": "Advanced Options and Settings."
            },
            outputs: {
                'tabChangedEvent': (event: any) => {
                    this.tabChanged(event);
                }
            }
        },
    ];

    //*************************************************************************
    //  Component Life-Cycle Methods
    //*************************************************************************
    constructor() {
        super();
    }

    public async ngOnInit() {
        await super.ngOnInit();
    }

    public tabChanged(event: any) {
        this.isLoading = event;
    }
}
