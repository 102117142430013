import { ChangeDetectorRef, Component, ComponentRef, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService, MsalBroadcastService } from '@azure/msal-angular';
import { AccountInfo, InteractionStatus } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { Variables, VariableType } from '~/core/classes/user/variables';

import { GraphService } from '~/core/services/graph.service';
import { AppConfigService } from '~/core/services/appconfig.service';
import { AppSettingsService } from '~/core/services/appsettings.service';

import { AppSettings } from '~/core/models/appsettings';

import { ConfigureComponent } from '~/core/components/configure/configure.component';

export interface Modal {
    type?: ComponentRef<any>;
    inputs?: any;
    outputs?: any
}

@Component({
    selector: 'app-root',
    standalone: false,
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    @ViewChild("page") page!: ElementRef | undefined;

    public appSettings: AppSettings = new AppSettings();

    public env: string = "";
    public error: string = "";
    public title: string = "";
    public version: string = "";
    public expand: boolean = false;

    public showNav: boolean = true;
    public showFooter: boolean = true;
    public showConfigure: boolean = false;

    public layoutCssClass: string = "";

    public isLoggedIn: boolean = false;
    public isLoading: boolean = true;

    // Alert
    public showAlert: boolean = false;
    public alertTitle: string = "Alert";
    public alertMessage: string = "";
    public alertContinueText: string = "OK";
    public alertCancelText: string = "";

    // Modals
    public modal: Modal | undefined;

    private readonly _destroying$ = new Subject<void>();

    //*************************************************************************
    //  Component Life-Cycle Methods
    //*************************************************************************
    constructor(
        private readonly authService: MsalService,
        private readonly msalBroadcastService: MsalBroadcastService,
        private readonly graphService: GraphService,
        private readonly router: Router,
        private readonly appConfigService: AppConfigService,
        private readonly appSettingsService: AppSettingsService,
        private readonly changeDetectorRef: ChangeDetectorRef,
    ) {
        window.onbeforeunload = null;
        sessionStorage.removeItem("__allowNavigate__");

        if (this.appConfigService.getConfig("environment")) {
            this.env = this.appConfigService.getConfig("environment").toString().toLocaleUpperCase();

            if (this.env === "LOCALHOST") {
                this.showConfigure = true;
            } else {
                this.showConfigure = false;
            }
        }
    }

    public async ngOnInit() {
        await this.initializeSettings();
    }

    public ngOnDestroy(): void {
        this._destroying$.next(undefined);
        this._destroying$.complete();
    }

    //*************************************************************************
    //  Public Methods
    //*************************************************************************
    public setLayoutClass(change: boolean = true) {
        if (this.appSettings.nav.sizable) {
            if (change) {
                if (this.layoutCssClass !== "") {
                    this.layoutCssClass = "";
                } else {
                    if (this.appSettings.nav.position === "top") {
                        this.layoutCssClass = "expand-top";
                    } else {
                        this.layoutCssClass = "expand";
                    }
                }

                Variables.set("Nav.LayoutCssClass", this.layoutCssClass, VariableType.Local);
            }

            if (this.layoutCssClass === "") {
                this.expand = false;
            } else {
                setTimeout(() => {
                    this.expand = true;

                    if (this.appSettings.nav.position === "top" && this.expand) {
                        this.layoutCssClass = "expand-top";
                    }

                    Variables.set("Nav.LayoutCssClass", this.layoutCssClass, VariableType.Local);
                }, 100);
            }
        }
    }

    public async reloadPage(page = "") {
        let currentUrl = page === "" ? this.router.url : page;

        this.router.navigate([currentUrl]);
    }

    public showConfigureModal() {
        this.modal = {
            type: <any>ConfigureComponent,
            inputs: {},
            outputs: {
                'applyEvent': async (event: any) => {
                    this.isLoading = true;

                    this.appSettings = await this.appSettingsService.getSettings();

                    document.location = "/home";
                },
                'closeEvent': (event: any) => {
                    this.modal = undefined;
                }
            }
        }
    }

    //*************************************************************************
    //  Alert Methods
    //*************************************************************************
    public alertContinue() {
        this.showAlert = false;
    }

    public alertCancel() {
        this.showAlert = false;
    }

    //*************************************************************************
    //  Private Methods
    //*************************************************************************
    private async initializeSettings() {
        let appSettings = await this.appSettingsService.getSettings();

        this.isLoggedIn = false;
        this.isLoading = true;

        if (appSettings) {
            this.appSettings = appSettings;

            if (this.appSettings.publicAccess || (!this.appSettings.publicAccess && this.appSettings.user)) {
                this.isLoggedIn = true;
                this.isLoading = false;
    
                await this.setUI();
            } else {
                await this.router.navigate(["/auth"]);
    
                if (!this.isLoggedIn) {
                    this.getMSALAccounts();
                } else {
                    await this.setUI();
                }
            }
        } else {
            this.error = "Unable to load application settings.";
            this.isLoading = false;
        }
    }

    private getMSALAccounts() {
        this.msalBroadcastService.inProgress$
            .pipe(
                filter((status: InteractionStatus) => status === InteractionStatus.None),
                takeUntil(this._destroying$)
            )
            .subscribe(() => {
                let account: AccountInfo | null = this.authService.instance.getActiveAccount();

                if (account === null) {
                    account = this.getAccountInfo();
                }

                this.setAccountInfo(account);
            });
    }

    private getAccountInfo() {
        let accounts = this.authService.instance.getAllAccounts();
        let account: AccountInfo | null = null;

        if (accounts.length > 0) {
            this.authService.instance.setActiveAccount(accounts[0]);
            account = accounts[0];
        }

        return account;
    }

    private async setAccountInfo(account: AccountInfo | null) {
        let allowAccess: boolean = this.appSettings.publicAccess;
        let roles: any = [];

        for (let role of this.appSettings.roles) {
            let groupAccess: any = await this.graphService.getUserGroups(role);

            if (groupAccess?.value.length > 0) {
                roles.push(role);
            }
        }

        if (this.appSettings.roles.length === 0 || (this.appSettings.roles.length > 0 && roles.length > 0)) {
            allowAccess = true;
        }

        if (account) {
            this.setUserInfo(account, roles);
            await this.appSettingsService.saveSettings(this.appSettings);
        }

        if (allowAccess) {
            if (this.appSettings.nav.sizable) {
                this.layoutCssClass = "";
            }

            this.isLoggedIn = true;
        } else {
            this.layoutCssClass = "noaccess";
            this.isLoggedIn = false;
        }

        Variables.set("Nav.LayoutCssClass", this.layoutCssClass, VariableType.Local);

        if (this.isLoggedIn) {
            await this.setUI();
        }
        
        this.isLoading = false;
    }

    private setUserInfo(account: any, roles: any) {
        account.photo = "";

        if (account.name) {
            const name: string[] = account.name.split(", ");

            if (name && name.length > 1) {
                const firstName: string = name[1].split(" (")[0].split(" [")[0];
                const lastName: string = name[0];

                account.firstName = firstName;
                account.lastName = lastName;
                account.name = `${firstName} ${lastName}`;
                account.initials = firstName.substring(0, 1).toUpperCase() + lastName.substring(0, 1).toUpperCase();
            }
        }

        account.roles = roles;

        this.appSettings.user = account;
    }

    private async setUI() {
        this.changeDetectorRef.detectChanges();

        let element = this.page?.nativeElement;

        if (element) {
            element.style.setProperty("--header-height", this.appSettings.header.height);
            element.style.setProperty("--nav-height", "50px");
            element.style.setProperty("--footer-height", this.appSettings.footer.height);
        }

        this.title = this.appSettings.title;
        this.version = this.appSettings.version;
        this.showNav = this.appSettings.nav.visible;
        this.showFooter = (this.appSettings.footer.locked || !this.appSettings.footer.custom) ? this.appSettings.footer.visible : false;

        if (!this.appSettings.nav.sizable) {
            this.expand = true;

            if (this.appSettings.nav.position === "top") {
                this.layoutCssClass = "expand-top";
            } else {
                this.layoutCssClass = "expand";
            }

            Variables.set("Nav.LayoutCssClass", this.layoutCssClass, VariableType.Local);
        } else {
            this.layoutCssClass = "";

            setTimeout(() => {
                if (this.isLoggedIn) {
                    this.layoutCssClass = Variables.get("Nav.LayoutCssClass", VariableType.Local) ?? "";
                    this.setLayoutClass(false);
                }
            }, 100);
        }
    }
}
