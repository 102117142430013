<div #page *ngIf="isLoggedIn">
	<core-header *ngIf="!appSettings.header.custom" [title]="title" (reloadPage)="reloadPage()"></core-header>
	<header *ngIf="appSettings.header.custom" [title]="title"></header>

	<nav *ngIf="showNav" [class]="layoutCssClass" [ngClass]="{ 'no-footer': !showFooter, 'top-nav': appSettings.nav.position === 'top' }" [expand]="expand" (setLayoutClass)="setLayoutClass()" (reloadPage)="reloadPage()"></nav>

	<content [class]="layoutCssClass" [ngClass]="{ 'no-nav': !showNav, 'no-footer': !showFooter, 'top-nav': appSettings.nav.position === 'top' }">
		<router-outlet></router-outlet>
	</content>

	<core-footer *ngIf="showFooter && !appSettings.footer.custom" [version]="version"></core-footer>
	<footer *ngIf="showFooter && appSettings.footer.custom && appSettings.footer.locked" [version]="version"></footer>
</div>

<div *ngIf="showConfigure" class="configure">
    <div class="icon" title="Configure App Settings" (click)="showConfigureModal()">settings</div>
</div>

<div *ngIf="modal" class="modal-window">
	<div class="locked"></div>
	<ng-container [ngComponentOutlet]="modal.type" [ndcDynamicInputs]="modal.inputs" [ndcDynamicOutputs]="modal.outputs"></ng-container>
</div>

<div *ngIf="!isLoggedIn && layoutCssClass !== 'noaccess'">
    <div class="auth">Authenticating...</div>
</div>

<access *ngIf="layoutCssClass === 'noaccess'"></access>
<error *ngIf="error !== ''" [message]="error"></error>

<div *ngIf="showAlert" class="modal-window">
	<div class="locked"></div>
	<alert
		[title]="alertTitle"
		[message]="alertMessage"
		[continueText]="alertContinueText"
		[cancelText]="alertCancelText"
		(continueEvent)="alertContinue()"
		(cancelEvent)="alertCancel()"></alert>
</div>

<sdk-loading [isLoading]="isLoading"></sdk-loading>
