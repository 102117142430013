import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AppSettingsService } from '~/core/services/appsettings.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard {
    //*************************************************************************
    //  Component Life-Cycle Methods
    //*************************************************************************
    constructor(
        private readonly router: Router,
        private readonly appSettingsService: AppSettingsService,
    ) { }

    //*************************************************************************
    //  Public Methods
    //*************************************************************************
    public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let appSettings = await this.appSettingsService.getSettings();

        if (!appSettings.publicAccess) {
            if (!appSettings.user) {
                if (!sessionStorage.getItem("_redirect")) {
                    sessionStorage.setItem("_redirect", state.url);
                }
            } else {
                let access: boolean = false;
                let config: any = route.routeConfig;

                if (config?.roles?.length > 0) {
                    let results = config.roles.filter((role: any) => appSettings.user.roles.some((user: any) => role.includes(user)));

                    if (results.length > 0) {
                        access = true;
                    }
                } else {
                    access = true;
                }

                if (!access) {
                    this.router.navigate(["/access"], { state: { type: "page" } });
                }
            }
        }
    }
}
