import { Component, Input } from '@angular/core';

@Component({
    selector: 'header',
	standalone: false,
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})

export class HeaderComponent {
    @Input() title = "";

    //*************************************************************************
    //  Component Life-Cycle Methods
    //*************************************************************************

    //*************************************************************************
    //  Public Methods
    //*************************************************************************

    //*************************************************************************
    //  Private Methods
    //*************************************************************************
}
